import 'lazysizes'
import 'flowbite'
import { iconAnimations, scrollFadeIn } from './helpers.js'
import '../blade-components/ppc-category-header'

window.iconAnimations = iconAnimations
window.scrollFadeIn = scrollFadeIn

// Hero section heading text  animation
// Hero section heading text animation
window.addEventListener('hero-section-heading-text-updated', () => {
  const heroSection = document.querySelector('#hero-section-heading-text');
    if (heroSection) {
      heroSection.querySelectorAll('[x-data]').forEach((el) => {
        const hasStartAnimation = Alpine.evaluate(el, 'typeof startAnimation === "function"');
        if (hasStartAnimation) {
            Alpine.evaluate(el, 'startAnimation()');
        }
      });
    }
});

// Searcher border animation
document.addEventListener('DOMContentLoaded', () => {
  const borderBeamElement = document.getElementById('border-beam');

  const rotateGradient = (angle) => {
    // #4C5ECF, #9873F8, transparent
    const direction = `${angle + 'deg'}`;
    borderBeamElement.style.setProperty('--gradient-direction', direction);
  };

  const calculateAngle = (offsetDistance, rangeStart, rangeEnd, radius = 40) => {
    const percentage = (rangeEnd - offsetDistance) / (rangeEnd - rangeStart);

    // Arc length for a quarter-circle: (pi * radius) / 2
    const totalArcLength = (Math.PI * radius) / 2;

    // Calculate the arc length for the given percentage along the curve
    const arcLength = totalArcLength * percentage;

    // Calculate the angle in radians (arc length / radius)
    const angleInRadians = arcLength / radius;

    // Convert to degrees
    const angleInDegrees = angleInRadians * (180 / Math.PI);

    return 270 - angleInDegrees;
  };

  const processPseudoElement = (styles, rangeStart, rangeEnd, resetAngle = 270) => {
    const offsetDistance = parseFloat(styles.offsetDistance);

    if (offsetDistance >= rangeStart && offsetDistance <= rangeEnd) {
      const angle = calculateAngle(offsetDistance, rangeStart, rangeEnd);
      rotateGradient(angle);
    } else {
      rotateGradient(resetAngle);
    }
  };

  const observePseudoElementsRotation = () => {
    const beforeStyles = getComputedStyle(borderBeamElement, '::before');
    const afterStyles = getComputedStyle(borderBeamElement, '::after');

    processPseudoElement(beforeStyles, 142.3, 150);
    processPseudoElement(beforeStyles, 92.3, 100);
    processPseudoElement(afterStyles, 42.3, 50);
    processPseudoElement(afterStyles, 92.3, 100);
  };

  // Configurar un intervalo para observar periódicamente
  setInterval(observePseudoElementsRotation, 100); // Revisar cada 100ms
});
