import anime from "animejs";

export const iconAnimations = function (
  containerSelector,
  imageSelector,
  hiddenClass = "hidden",
  initialDelay = 1000,
  widthIncrement = 50,
  translateX = 28
) {
  const duration = 700; // Duration in milliseconds
  const easingFunction = "easeOutElastic(1, 0.5)"; // Elastic easing effect

  return {
    // Initial data
    animateImages: [],

    // Utility function to apply animations
    applyAnimation(targets, properties) {
      anime({
        targets,
        ...properties,
      });
    },

    // Show hidden images by removing their hidden class
    showHiddenImages() {
      document
        .querySelectorAll(`${imageSelector}.${hiddenClass}`)
        .forEach((img) => {
          img.style.display = "block";
        });
    },

    // Reset images: opacity and visibility
    resetImages() {
      // Reset opacity of the first image
      this.applyAnimation(`${imageSelector}.opacity-50`, {
        opacity: 0.5,
        duration: 0,
        easing: "linear",
      });

      // Hide other images with the hidden class
      this.applyAnimation(`${imageSelector}.${hiddenClass}`, {
        opacity: 0,
        duration: duration,
        easing: "easeOutQuad",
        complete: () => {
          document
            .querySelectorAll(`${imageSelector}.${hiddenClass}`)
            .forEach((img) => {
              img.style.display = "none";
            });
        },
      });
    },

    // Reset the position of all images
    resetImagePositions() {
      document.querySelectorAll(imageSelector).forEach((img) => {
        this.applyAnimation(img, {
          translateX: 0,
          duration: duration,
          easing: easingFunction,
        });
      });
    },

    // Reset the container size
    resetContainerSize() {
      this.applyAnimation(containerSelector, {
        width: `-=${widthIncrement}px`, // Reduce container width
        duration: duration,
        easing: easingFunction,
      });
    },

    // Animate image positions
    animateImagePositions() {
      document.querySelectorAll(imageSelector).forEach((img, index) => {
        if (index > 0) {
          this.animateImages.push(index);
          this.applyAnimation(img, {
            translateX: index * translateX, // Adjust horizontal offset
            duration: duration,
            easing: easingFunction,
          });
        }
      });
    },

    // Animate the container size
    animateContainerSize() {
      this.applyAnimation(containerSelector, {
        width: `+=${widthIncrement}px`, // Increase container width
        duration: duration,
        easing: easingFunction,
      });
    },

    // Start the animation sequence
    startAnimation() {
      setTimeout(() => {
        // Fade in the first image
        this.applyAnimation(`${imageSelector}.opacity-50`, {
          opacity: 1,
          duration: 0,
          easing: "linear",
        });

        // Fade in hidden images
        this.applyAnimation(`${imageSelector}.${hiddenClass}`, {
          opacity: 1,
          duration: duration,
          easing: "easeOutQuad",
          begin: this.showHiddenImages,
        });

        // Animate image positions and container size
        this.animateImagePositions();
        this.animateContainerSize();
      }, initialDelay);

      // Reset animation after 7.5 seconds
      setTimeout(() => {
        this.resetImages();
        this.resetImagePositions();
        this.resetContainerSize();
      }, 7500);
    },
  };
};

export const scrollFadeIn = function (element) {
  return {
    observer: null,
    target: element,
    executed: false, // Bandera para evitar múltiples ejecuciones
    init() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.executed) {
            this.executed = true; // Marca como ejecutado

            // Aplica la animación
            anime({
              targets: this.target,
              opacity: [0, 1],
              translateY: [150, 0], // Ajuste para mayor movimiento
              easing: 'easeOutQuad',
              duration: 750,
            });

            // Desconecta el observador para liberar recursos
            this.observer.disconnect();
          }
        });
      }, { threshold: 0.1 }); // Dispara al 10% visible

      this.observer.observe(this.target);
    }
  };
}
